import React, { useState, useEffect } from 'react'
import { api } from '../../services/api'
import 'react-datepicker/dist/react-datepicker.css';
import { useForm } from 'react-hook-form';
import UlProfessr from '../../components/UlProfessor'
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import ptBrLocale from '@fullcalendar/core/locales/pt-br';
import interactionPlugin from '@fullcalendar/interaction';
import ConfirmDialog from '../../components/ConfirmDialog/ConfirmDialog';
import GroupButtons from '../../components/GroupButtons';

const MarcarAula = () => {
  const [token, setToken] = useState(0);
  const [error, setError] = useState('');
  const [userEmail, setUserEmail] = useState('');
  const [selectedDate, setSelectedDate] = useState(null);
  const [successMessage, setSuccessMessage] = useState('');
  const [selectedTopic, setSelectedTopic] = useState('informática');
  const [selectedTeacher, setSelectedTeacher] = useState(null);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [eventInfo, setEventInfo] = useState(null);
  const [freeSlots, setFreeSlots] = useState([])
  const [isLoading, setIsLoading] = useState(false);

  const { reset } = useForm();

  const handleGet = async () => {
    try {
      const response = await api.get('/user-class', {});
      console.log(response);
      setToken(response.data.user.tokens);
      setUserEmail(response.data.user.email);
      setError('');
    } catch (err) {
      console.log(err);
      setError('Houve um problema ao consultar suas informações T.T');
      setTimeout(() => setError(''), 5000);
    }
  };

  const fetchFreeSlots = async (teacherId) => {
    try {
      const response = await api.get('/free-slots', {
        params: { teacherId },
      });
      setFreeSlots(response.data.freeSlots);
    } catch (err) {
      const errorMessage = err.response?.data?.message || 'Erro ao buscar horários livres.';
      setError(errorMessage);
      setTimeout(() => setError(''), 5000);
      setFreeSlots([])
    }
  };

  const handleTopicChange = (topic) => {
    setSelectedTopic(topic);
  };

  const handleTeacherClick = (teacher) => {
    setSelectedTeacher(teacher);
    fetchFreeSlots(teacher.id)
  };

  const handleEventClick = (info) => {
    setSelectedDate(info.event.start)
    const eventDate = info.event.start;
    setEventInfo({
      date: eventDate,
      teacherName: selectedTeacher?.name || '',
    });
    setIsDialogOpen(true); // Abrir o diálogo
  };

  const handleDialogClose = () => {
    setIsDialogOpen(false);
  };

  // Função para enviar a solicitação para o endpoint /reservation-request
  const sendReservationRequest = async () => {
    if (!selectedTeacher || !selectedDate || !userEmail) {
      setError('Por favor, selecione um professor e uma data.');
      setTimeout(() => setError(''), 5000);
      return;
    }

    setIsLoading(true);
    try {
      const slot = freeSlots.find(slot => new Date(slot.date).getTime() === new Date(selectedDate).getTime());

      if (!slot) {
        setError('Nenhum horário disponível foi selecionado.');
        setTimeout(() => setError(''), 5000);
        return;
      }

      const requestData = {
        studentEmail: userEmail,
        teacherId: selectedTeacher.id,
        slotId: slot._id,
      };

      console.log('Enviando solicitação para /reservation-request:', requestData);
      const response = await api.post('/reservation-request', requestData);

      setSuccessMessage('Solicitação de reserva enviada com sucesso!');
      setTimeout(() => setSuccessMessage(''), 6000);
      reset();
      setSelectedDate(null);
      handleGet();
      fetchFreeSlots(selectedTeacher.id)
    } catch (err) {
      const errorMessage = err.response?.data?.message || 'Erro ao solicitar reserva. Tente novamente mais tarde.';
      if (err.response?.status === 400) {
        setError(errorMessage); 
      } else {
        setError('Erro ao solicitar reserva. Tente novamente mais tarde.'); 
      }
      setTimeout(() => setError(''), 5000);
      console.error(err);
    } finally {
      setIsLoading(false);
    }
  };

  const handleDialogConfirm = async () => {
    setIsDialogOpen(false);
    setError('');
    setSuccessMessage('');

    try {
      await sendReservationRequest(); // Chama a função que envia a solicitação
    } catch (err) {
      setError('Ocorreu um erro ao confirmar a solicitação.');
      console.error(err);
    }
  };

  useEffect(() => {
    handleGet();
  }, []);

  const topics = ['informática', 'matemática', 'física'];

  return (
    <div className="py-4 flex flex-col h-full ">
      {/* Pop-up de erro */}
      {error && (
        <div className="fixed bottom-4 z-50 right-4 bg-red-500 text-white px-4 py-2 rounded">
          {error}
        </div>
      )}

      {isLoading && (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50 z-50">
          <div className="bg-white p-6 rounded-lg shadow-lg">
            <p><strong>Enviando solicitação...</strong></p>
          </div>
        </div>
      )}

      {/* Pop-up de sucesso */}
      {successMessage && (
        <div className="fixed bottom-4 z-50 right-4 bg-green-500 text-white px-4 py-2 rounded">
          {successMessage}
        </div>
      )}

      {/* Botões de navegação */}
      <GroupButtons token={token} />

      <div className='scd-overlay'>
        <div className='scd-container'>
          <div className="w-full flex pl-7">
            {topics.map((topic) => (
              <button
                key={topic}
                className={selectedTopic === topic ? 'g-selected-btn' : 'g-btn bg-gray-100'}
                onClick={() => handleTopicChange(topic)}
              >
                {topic.toUpperCase()}
              </button>
            ))}
          </div>

          <div className="flex w-full h-full bg-gray-100 radius-5 rounded-md p-6 shadow-[2px_3px_5px_0px_rgba(0,0,0,0.15)]">
            <div className="min-w-80 mr-6 bg-white py-4 rounded-md flex flex-col items-center shadow-[2px_3px_5px_0px_rgba(0,0,0,0.15)]">
              <h3 className=" px-4 font-bold mb-10 text-orange-500 text-2xl">NOSSOS PROFESSORES</h3>
              <UlProfessr topico={selectedTopic} onTeacherClick={handleTeacherClick} selectedTeacher={selectedTeacher} />
            </div>

            <div className="flex-grow bg-white p-4 shadow-[2px_3px_5px_0px_rgba(0,0,0,0.15)]">
              {selectedTeacher != null && <>
                <h3 className="font-bold text-center text-2xl mb-4">
                  Horários livres - {selectedTeacher.name}
                </h3>
                <div className="">
                  <FullCalendar
                    plugins={[dayGridPlugin, interactionPlugin, timeGridPlugin]}
                    initialView="timeGridWeek"
                    locale={ptBrLocale}
                    views={["dayGridMonth", "timeGridWeek", "dayGridDay"]}
                    events={freeSlots.map((slot) => ({
                      title: 'Disponível',
                      date: slot.date,
                      id: slot._id,
                      extendedProps: {
                        teacherId: slot.teacherId,     
                        isAvailable: slot.isAvailable, 
                      },
                    }))}
                    headerToolbar={{
                      start: "today prev next",
                      end: "dayGridMonth timeGridWeek dayGridDay"
                    }}
                    eventClick={handleEventClick} 
                    width="auto"
                    slotLabelFormat={{
                      hour: '2-digit',
                      minute: '2-digit',
                      meridiem: false,
                      hour12: false,
                    }}
                    expandRows={true}
                    style={{
                      height: '100%',
                      width: '100%'
                    }}
                  />
                </div>
              </>}
            </div>
          </div>
        </div>
      </div>

      {/* Diálogo de confirmação */}
      <ConfirmDialog
        isOpen={isDialogOpen}
        onClose={handleDialogClose}
        onConfirm={handleDialogConfirm}
        eventInfo={eventInfo}
      />
    </div>
  );
};

export default MarcarAula;