import React, { useState } from 'react'
import { api } from '../../services/api'
import { Link } from 'react-router-dom/cjs/react-router-dom.min'
import GroupButtons from '../../components/GroupButtons'

const TopicDetails = ({ topic, index }) => {
    return (
        <div className="pb-3">
            <div className="collapse bg-base-100">
                <input
                    type="checkbox"
                    name={`topic-${index}`}
                    checked={true}
                    disabled
                />
                <div className="collapse-title text-2xl font-medium">
                    {topic.title}
                </div>
                <div className="collapse-content">
                    <div>
                        <h4 className="font-bold text-xl">Materiais</h4>
                        <ul>
                            {topic.materials.map((material, materialIndex) => (
                                <li
                                    key={materialIndex}
                                    className="flex items-center space-x-2"
                                >
                                    <input
                                        type="checkbox"
                                        checked={material.done}
                                        disabled
                                    />
                                    <a
                                        href={material.link}
                                        className="link text-lg"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        {material.title}
                                    </a>
                                </li>
                            ))}
                        </ul>
                    </div>
                    <div>
                        <h4 className="font-bold text-xl">Exercícios</h4>
                        <ul>
                            {topic.exercises.map((exercise, exerciseIndex) => (
                                <li
                                    key={exerciseIndex}
                                    className="flex items-center space-x-2"
                                >
                                    <input
                                        type="checkbox"
                                        checked={exercise.done}
                                        disabled
                                    />
                                    <a
                                        className="link text-lg"
                                        href={exercise.link}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        {exercise.title}
                                    </a>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    )
}

const AulaDetails = ({ aula, number }) => {
    return (
        <div className="py-3 px-5">
            <div className="collapse bg-base-200">
                <input type="radio" name="my-accordion-1" />
                <div className="collapse-title text-3xl font-medium text-secondary">
                    Aula #{number}
                </div>
                <div className="collapse-content">
                    {aula.topics.map((topic, topicIndex) => (
                        <TopicDetails
                            key={topicIndex}
                            topic={topic}
                            index={topicIndex}
                        />
                    ))}
                </div>
            </div>
        </div>
    )
}

const BuscarAluno = () => {
    const [email, setEmail] = useState('')
    const [error, setError] = useState('')
    const [aulas, setAulas] = useState([])
    const [token, setToken] = useState(0)

    const handleGetUserInfo = async () => {
        try {
            console.log(email)
            const response = await api.get('/get-user-info', {
                params: { email }, // Passa o email como parâmetro de consulta
            })
            setAulas(response.data.user.aulas)
            setToken(response.data.user.tokens)
            setError('')
        } catch (err) {
            console.error(err)
            setError(
                'Houve um problema ao consultar as informações do usuário.'
            )
        }
    }

    return (
        <div className="py-4">
             <div className="w-full flex items-center justify-center">
                {error && (
                    <div className="pb-4">
                        <div role="alert" className="alert alert-error col-span-full">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="stroke-current shrink-0 h-6 w-6"
                                fill="none"
                                viewBox="0 0 24 24"
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
                                />
                            </svg>
                            <span>{error}</span>
                        </div>
                    </div>
                )}
            </div>
            
            <GroupButtons professor={true}/>
            
            <div className="px-4 py-2">
                <input
                    type="text"
                    placeholder="Digite o e-mail do usuário"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    className="input input-bordered w-full mb-2"
                />
                <button
                    onClick={handleGetUserInfo}
                    className="btn btn-primary w-full"
                >
                    Buscar Informações do Usuário
                </button>
            </div>
            <div className="w-full">
                {aulas.length > 0 ? (
                    aulas
                        .slice()
                        .reverse()
                        .map((aula, index) => (
                            <AulaDetails
                                key={index}
                                aula={aula}
                                number={aulas.length - index}
                            />
                        ))
                ) : (
                    <h2 className="text-center text-3xl font-bold py-4">
                        Sem aulas.
                    </h2>
                )}
            </div>
        </div>
    )
}

export default BuscarAluno
