import React, { useEffect, useState } from 'react'
import { api } from '../../services/api'
import GroupButtons from '../../components/GroupButtons'

const TopicDetails = ({ topic, index, updateStatus }) => {
    return (
        <div className="pb-3">
            <div className="collapse bg-base-100">
                <input type="checkbox" name={`topic-${index}`} />
                <div className="collapse-title text-2xl font-medium">
                    {topic.title}
                </div>
                <div className="collapse-content">
                    <div>
                        <h4 className="font-bold text-xl">Materiais</h4>
                        <ul>
                            {topic.materials.map((material, materialIndex) => (
                                <li
                                    key={materialIndex}
                                    className="flex items-center space-x-2"
                                >
                                    <input
                                        type="checkbox"
                                        checked={material.done}
                                        onChange={(e) =>
                                            updateStatus(
                                                'material',
                                                materialIndex,
                                                e.target.checked
                                            )
                                        }
                                    />
                                    <a
                                        href={material.link}
                                        className="link text-lg"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        {material.title}
                                    </a>
                                </li>
                            ))}
                        </ul>
                    </div>
                    <div>
                        <h4 className="font-bold text-xl">Exercícios</h4>
                        <ul>
                            {topic.exercises.map((exercise, exerciseIndex) => (
                                <li
                                    key={exerciseIndex}
                                    className="flex items-center space-x-2"
                                >
                                    <input
                                        type="checkbox"
                                        checked={exercise.done}
                                        onChange={(e) =>
                                            updateStatus(
                                                'exercise',
                                                exerciseIndex,
                                                e.target.checked
                                            )
                                        }
                                    />
                                    <a
                                        className="link text-lg"
                                        href={exercise.link}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        {exercise.title}
                                    </a>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    )
}

const AulaDetails = ({ aula, number, updateStatus, aulaIndex }) => {
    return (
        <div className="py-3 px-5">
            <div className="collapse bg-base-200">
                <input type="radio" name="my-accordion-1" />
                <div className="collapse-title text-3xl font-medium text-secondary">
                    Aula #{number}
                </div>
                <div className="collapse-content">
                    {aula.topics.map((topic, topicIndex) => (
                        <TopicDetails
                            key={topicIndex}
                            topic={topic}
                            index={topicIndex}
                            updateStatus={(type, index, done) =>
                                updateStatus(
                                    aulaIndex,
                                    topicIndex,
                                    index,
                                    type,
                                    done
                                )
                            }
                        />
                    ))}
                </div>
            </div>
        </div>
    )

}

const ListaAulas = () => {
    const [token, setToken] = useState(0);
    const [error, setError] = useState('');
    const [aulas, setAulas] = useState([]);
    
    const handleGet = async () => {
        try {
            const response = await api.get('/user-class', {});
            setToken(response.data.user.tokens);
            setAulas(response.data.user.aulas);
            setError('');
        } catch (err) {
            console.log(err);
            setError('Houve um problema com ao consultar suas informações T.T');
            setTimeout(() => setError(''), 5000);
        }
    };

    useEffect(() => {
        handleGet();
    }, []);

    const updateStatus = async (aulaIndex, topicIndex, index, type, done) => {
        try {
            await api.post('/update-done-status', {
                aulaIndex,
                topicIndex,
                index,
                type,
                done,
            });
            handleGet(); // Refresh the data after update
        } catch (err) {
            console.error(err);
            setError('Houve um problema ao atualizar o status.');
            setTimeout(() => setError(''), 5000);
        }
    };


    return (
        <div className="py-4">
            <GroupButtons token={token} />
            {error && (
                <div className="fixed bottom-4 z-50 right-4 bg-red-500 text-white px-4 py-2 rounded">
                    {error}
                </div>
            )}

            <div className="w-full">
                {aulas.length > 0 ? (
                    aulas
                        .slice()
                        .reverse()
                        .map((aula, index) => (
                            <AulaDetails
                                key={index}
                                aula={aula}
                                number={aulas.length - index}
                                aulaIndex={aulas.length - index - 1}
                                updateStatus={(
                                    aulaIndex,
                                    topicIndex,
                                    index,
                                    type,
                                    done
                                ) =>
                                    updateStatus(
                                        aulaIndex,
                                        topicIndex,
                                        index,
                                        type,
                                        done
                                    )
                                }
                            />
                        ))
                ) : (
                    <h2 className="text-center text-3xl font-bold py-4">
                        Suas aulas aparecerão aqui :D
                    </h2>
                )}
            </div>
        </div>
    )
}

export default ListaAulas;