import React, { useState, useEffect } from 'react'
import { api } from '../../services/api'
import { Link } from 'react-router-dom/cjs/react-router-dom.min'
import GroupButtons from '../../components/GroupButtons';

const TeacherViewPage = ({ history }) => {
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(true); 

    const handleGet = async () => {
        try {
            const response = await api.get('/user-class', {});
            const userResponse = await api.get('/user-info', {});
            setError('');
        } catch (err) {
            console.log(err);
            setError('Houve um problema com ao consultar suas informações T.T');
        } finally {
            setLoading(false); // Atualiza o estado de carregamento ao finalizar
        }
    };

    useEffect(() => {
        handleGet();
    }, []);

    if (loading) {
        return <div className="text-center text-2xl"></div>; // Exibe uma mensagem de carregamento
    }

    return (
        <div className="py-4">
            <div className="w-full flex items-center justify-center">
                {error && (
                    <div className="pb-4">
                        <div role="alert" className="alert alert-error col-span-full">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="stroke-current shrink-0 h-6 w-6"
                                fill="none"
                                viewBox="0 0 24 24"
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
                                />
                            </svg>
                            <span>{error}</span>
                        </div>
                    </div>
                )}
            </div>
           
           <GroupButtons professor={true}/>

            <div className="w-full">

            </div>

        </div>
    );
};

export default TeacherViewPage;