import React from 'react'
import {
    FaChalkboardTeacher,
    FaVideo,
    FaWhatsapp,
    FaClipboardList,
    FaCommentDots,
    FaCertificate,
} from 'react-icons/fa'

const benefits = [
    {
        icon: <FaChalkboardTeacher size={100} />,
        title: 'Aulas particulares',
        text: 'Aulas particulares com horários flexíveis',
    },
    {
        icon: <FaVideo size={100} />,
        title: 'Gravação + materiais',
        text: 'Gravação e materiais exclusivos após cada aula',
    },
    {
        icon: <FaWhatsapp size={100} />,
        title: 'Suporte',
        text: 'Suporte no WhatsApp para dúvidas e planos de estudo',
    },
    {
        icon: <FaClipboardList size={100} />,
        title: 'Simulados',
        text: 'Simulados regulares para avaliação de desempenho',
    },
    {
        icon: <FaCommentDots size={100} />,
        title: 'Feedback',
        text: 'Feedback detalhado após cada aula',
    },
    {
        icon: <FaCertificate size={100} />,
        title: 'Certificado',
        text: 'Certificado de conclusão após 12 aulas',
    },
]

const BenefitsGrid = () => {
    return (
        <div className="p-8">
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-12">
                {benefits.map((benefit, index) => (
                    <div
                        key={index}
                        className="flex flex-col items-center p-4 shadow-md rounded-lg max-w-[300px] mx-auto"
                    >
                        <div className="flex items-center justify-center w-32 h-32 mb-2 bg-light-blue rounded-full">
                            <div className="text-accent">{benefit.icon}</div>
                        </div>
                        <h3 className="text-3xl font-bold mb-1 text-center text-accent">
                            {benefit.title}
                        </h3>
                        <p className="text-gray-600 text-center text-xl">
                            {benefit.text}
                        </p>
                    </div>
                ))}
            </div>
        </div>
    )
}

export default BenefitsGrid
