const GratisMat = ({ history }) => {
    return (
        <div>
            <div className="hero min-h-[93vh]">
                <div className="hero-content flex-col lg:flex-row-reverse w-full">
                    <div className="w-full lg:max-w-[45%]">
                        <h1 className="text-6xl font-bold">
                            RESOLVENDO A{' '}
                            <span className="text-secondary">JACOB PALIS</span>
                        </h1>
                        <p className="py-6 text-2xl font-bold">
                            Junte-se a nós em uma live onde vamos resolver as
                            questões da Olimpíada Jacob Palis e dar dicas
                            valiosas para a preparação da Olimpíada Brasileira
                            de Matemática (OBM)!
                        </p>
                    </div>
                    <div className="w-full">
                        <div className="relative w-full overflow-hidden pt-[56.25%]">
                            <p>
                                <iframe
                                    className="absolute top-0 left-0 right-0 w-full h-full border-none"
                                    src="https://www.youtube.com/embed/Yp4ALmD-Omo"
                                    width="560"
                                    title="Live Intensivão podio.digital"
                                    height="315"
                                    allowFullScreen="allowFullScreen"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                ></iframe>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default GratisMat
