import React, { useState } from 'react'
import { Link, withRouter } from 'react-router-dom'
import { api } from '../../services/api'
import Header from '../../components/Header'
import Logo from '../../assets/logo.png'

const RequestMudarSenha = ({ history }) => {
    const [email, setEmail] = useState('')
    const [error, setError] = useState('')
    const [success, setSuccess] = useState(false)
    const [disabled, setDisabled] = useState(false)

    const handleRequestMudarSenha = async (e) => {
        e.preventDefault()
        if (!email) {
            setError('Preencha todos os dados para se cadastrar')
        } else {
            try {
                setDisabled(true)
                await api.post('/request-change', { email })
                setSuccess(true)
            } catch (err) {
                console.log(err)
                if (
                    err.response.data.message ===
                    'Account not activated, email sent'
                ) {
                    setError(
                        'Sua conta ainda não foi ativada, enviamos um email para ativá-la.'
                    )
                } else {
                    setError('Ocorreu um erro ao requisitar a mudança. T.T')
                    setDisabled(false)
                }
            }
        }
    }

    if (!success) {
        return (
            <div className="flex items-center justify-center min-h-[93vh]">
                <div className="flex flex-col items-center w-400 py-3 px-3">
                    <Header
                        heading="Mudança de senha"
                        paragraph="Lembrou sua senha?"
                        linkName="Login"
                        linkUrl="/login"
                    />
                    <form
                        onSubmit={handleRequestMudarSenha}
                        className="w-[350px]"
                    >
                        {error && (
                            <div className="pb-4">
                                <div
                                    role="alert"
                                    className="alert alert-error col-span-full"
                                >
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        className="stroke-current shrink-0 h-6 w-6"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                    >
                                        <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth="2"
                                            d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
                                        />
                                    </svg>
                                    <span>{error}</span>
                                </div>
                            </div>
                        )}
                        <div className="pb-4">
                            <label className="input input-bordered flex items-center gap-2 w-full">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 16 16"
                                    fill="currentColor"
                                    className="w-4 h-4 opacity-70"
                                >
                                    <path d="M2.5 3A1.5 1.5 0 0 0 1 4.5v.793c.026.009.051.02.076.032L7.674 8.51c.206.1.446.1.652 0l6.598-3.185A.755.755 0 0 1 15 5.293V4.5A1.5 1.5 0 0 0 13.5 3h-11Z" />
                                    <path d="M15 6.954 8.978 9.86a2.25 2.25 0 0 1-1.956 0L1 6.954V11.5A1.5 1.5 0 0 0 2.5 13h11a1.5 1.5 0 0 0 1.5-1.5V6.954Z" />
                                </svg>

                                <input
                                    type="email"
                                    placeholder="Endereço de e-mail"
                                    className="grow"
                                    onChange={(e) => setEmail(e.target.value)}
                                />
                            </label>
                        </div>
                        <button
                            type="submit"
                            className="btn btn-primary w-full"
                            disabled={disabled}
                        >
                            {disabled && (
                                <span className="loading loading-spinner"></span>
                            )}
                            Mudar senha
                        </button>
                    </form>
                </div>
            </div>
        )
    } else {
        return (
            <div className="hero min-h-screen bg-base-200">
                <div className="hero-content text-center flex-col lg:flex-row">
                    <div className="flex justify-center py-4">
                        <img alt="Logo" className="size-40" src={Logo} />
                    </div>
                    <div className="max-w-md">
                        <h1 className="text-5xl font-bold">
                            Confira sua caixa de entrada!
                        </h1>
                        <p className="py-6 text-lg">
                            Enviamos um email para mudar sua senha :D
                            <br />
                            <span className="text-info">
                                Confira a caixa de spam.
                            </span>
                        </p>
                        <Link className="btn btn-primary" to="/">
                            Voltar para página inicial
                        </Link>
                    </div>
                </div>
            </div>
        )
    }
}

export default withRouter(RequestMudarSenha)
