import React, { useState, useEffect } from 'react'
import { api } from '../../services/api'
import GroupButtons from '../../components/GroupButtons'

const UserPageView = ({ history }) => {
    const [token, setToken] = useState(0);
    const [error, setError] = useState(''); 

    const handleGet = async () => {
        try {
            const response = await api.get('/user-class', {});
            const userResponse = await api.get('/user-info', {});
            setToken(response.data.user.tokens);
            setError('');
        } catch (err) {
            console.log(err);
            setError('Houve um problema com ao consultar suas informações T.T');
            setTimeout(() => setError(''), 5000);
        }
    };

    useEffect(() => {
        handleGet();
    }, []);


    return (
        <div className="py-4">
            {error && (
                <div className="fixed bottom-4 z-50 right-4 bg-red-500 text-white px-4 py-2 rounded">
                    {error}
                </div>
            )}
            <GroupButtons professor={false} token={token} />
        </div>
    );
};

export default UserPageView;