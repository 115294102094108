import { useReducer } from 'react'
import { Link } from 'react-router-dom'
import Logo from '../../assets/logo.png'
import { withRouter } from 'react-router-dom/cjs/react-router-dom.min'
import { isAuthenticated, logout } from '../../services/auth'

function Header() {
    const [, forceUpdate] = useReducer((x) => x + 1, 0)

    function logoutButton() {
        logout()
        forceUpdate()
    }

    return (
        <div className="navbar bg-base-100">
            <div className="navbar-start">
                <div className="px-3">
                    <Link className="btn btn-square btn-ghost" to="/">
                        <img alt="Logo" className="size-12" src={Logo} />
                    </Link>
                </div>
                <Link className="btn btn-ghost normal-case text-xl" to="/">
                    <button>
                        <span className="text-primary">podio</span>
                        <span>.</span>
                        <span className="text-secondary">digital</span>
                    </button>
                </Link>
            </div>
            {/* For small ones */}
            <div className="navbar-end lg:hidden">
                <div className="dropdown">
                    <div
                        tabIndex={0}
                        role="button"
                        className="btn btn-ghost btn-circle"
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-8 w-8"
                            viewBox="0 0 24 24"
                            stroke="oklch(var(--s))"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                fill="oklch(var(--s))"
                                d="M4 6h16M4 12h16M4 18h7"
                            />
                        </svg>
                    </div>
                    <ul
                        tabIndex={0}
                        className="left-auto right-0 menu menu-sm dropdown-content mt-3 z-[1] p-2 shadow-3xl bg-base-200 rounded-box w-52"
                    >
                        <li className="pb-3">
                            <Link
                                to="/app"
                                className="btn btn-outline btn-secondary"
                            >
                                Planos
                            </Link>
                        </li>
                        <li className="pb-3">
                            <Link to="/#materiais" className="btn btn-primary">
                                Conteúdos Grátis
                            </Link>
                        </li>
                        <li className="pb-3">
                            <Link to="/#sobre-nos" className="btn btn-primary">
                                Sobre Nós
                            </Link>
                        </li>
                        <li>
                            <Link to="/#contato" className="btn btn-primary">
                                Contato
                            </Link>
                        </li>
                        {!isAuthenticated() && (
                            <div>
                                <li className="py-3">
                                    <Link
                                        to="/cadastro"
                                        className="btn btn-secondary"
                                    >
                                        Cadastro
                                    </Link>
                                </li>
                                <li>
                                    <Link
                                        to="/login"
                                        className="btn btn-secondary"
                                    >
                                        Login
                                    </Link>
                                </li>
                            </div>
                        )}
                        {isAuthenticated() && (
                            <div>
                                <li className="py-3">
                                    <Link
                                        to="/app"
                                        className="btn btn-secondary"
                                    >
                                        Mentoria
                                    </Link>
                                </li>
                                <li>
                                    <button
                                        onClick={logoutButton}
                                        className="btn btn-outline btn-secondary"
                                    >
                                        Logout
                                    </button>
                                </li>
                            </div>
                        )}
                    </ul>
                </div>
            </div>
            {/* For big ones */}
            <div className="hidden navbar-end lg:flex">
                <div className="flex-none pr-3">
                    <Link
                        to="/#mentoria"
                        className="btn btn-outline btn-secondary"
                    >
                        Planos
                    </Link>
                </div>
                <div className="flex-none">
                    <Link
                        to="/#materiais"
                        className="btn btn-outline btn-primary"
                    >
                        Conteúdos Grátis
                    </Link>
                </div>
                <div className="flex-none px-3">
                    <Link
                        to="/#sobre-nos"
                        className="btn btn-outline btn-primary"
                    >
                        Sobre Nós
                    </Link>
                </div>
                <div className="flex-none pr-3">
                    <Link to="/#contato" className="btn btn-outline btn-primary">
                        Contato
                    </Link>
                </div>
                {!isAuthenticated() && (
                    <div className="flex items-center space-x-3">
                        <Link
                            to="/cadastro"
                            className="btn btn-outline btn-secondary"
                        >
                            Cadastro
                        </Link>
                        <Link
                            to="/login"
                            className="btn btn-outline btn-secondary"
                        >
                            Login
                        </Link>
                    </div>
                )}
                {isAuthenticated() && (
                    <div className="flex items-center space-x-3">
                        <Link to="/app" className="btn btn-secondary">
                            Mentoria
                        </Link>
                        <button
                            onClick={logoutButton}
                            className="btn btn-outline btn-secondary"
                        >
                            Logout
                        </button>
                    </div>
                )}
            </div>
            <label className="swap swap-rotate px-3">
                {/* this hidden checkbox controls the state */}
                <input
                    type="checkbox"
                    className="theme-controller"
                    value="sunset"
                />

                {/* sun icon */}
                <svg
                    className="swap-off h-10 w-10 fill-current"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                >
                    <path d="M5.64,17l-.71.71a1,1,0,0,0,0,1.41,1,1,0,0,0,1.41,0l.71-.71A1,1,0,0,0,5.64,17ZM5,12a1,1,0,0,0-1-1H3a1,1,0,0,0,0,2H4A1,1,0,0,0,5,12Zm7-7a1,1,0,0,0,1-1V3a1,1,0,0,0-2,0V4A1,1,0,0,0,12,5ZM5.64,7.05a1,1,0,0,0,.7.29,1,1,0,0,0,.71-.29,1,1,0,0,0,0-1.41l-.71-.71A1,1,0,0,0,4.93,6.34Zm12,.29a1,1,0,0,0,.7-.29l.71-.71a1,1,0,1,0-1.41-1.41L17,5.64a1,1,0,0,0,0,1.41A1,1,0,0,0,17.66,7.34ZM21,11H20a1,1,0,0,0,0,2h1a1,1,0,0,0,0-2Zm-9,8a1,1,0,0,0-1,1v1a1,1,0,0,0,2,0V20A1,1,0,0,0,12,19ZM18.36,17A1,1,0,0,0,17,18.36l.71.71a1,1,0,0,0,1.41,0,1,1,0,0,0,0-1.41ZM12,6.5A5.5,5.5,0,1,0,17.5,12,5.51,5.51,0,0,0,12,6.5Zm0,9A3.5,3.5,0,1,1,15.5,12,3.5,3.5,0,0,1,12,15.5Z" />
                </svg>

                {/* moon icon */}
                <svg
                    className="swap-on h-10 w-10 fill-current"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                >
                    <path d="M21.64,13a1,1,0,0,0-1.05-.14,8.05,8.05,0,0,1-3.37.73A8.15,8.15,0,0,1,9.08,5.49a8.59,8.59,0,0,1,.25-2A1,1,0,0,0,8,2.36,10.14,10.14,0,1,0,22,14.05,1,1,0,0,0,21.64,13Zm-9.5,6.69A8.14,8.14,0,0,1,7.08,5.22v.27A10.15,10.15,0,0,0,17.22,15.63a9.79,9.79,0,0,0,2.1-.22A8.11,8.11,0,0,1,12.14,19.73Z" />
                </svg>
            </label>
        </div>
    )
}

export default withRouter(Header)
