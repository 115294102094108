import PdfAula1 from '../../../assets/intensivao-aula1.pdf'
import PdfAula2 from '../../../assets/intensivao-aula2.pdf'

const IntensivaoInfo = ({ history }) => {
    return (
        <div>
            <div className="hero pb-16 pt-5">
                <div className="hero-content flex-col lg:flex-row-reverse w-full min-h-[93vh]">
                    <div className="w-full">
                        <h3 className="text-center text-3xl pb-2 font-bold text-accent">
                            AULA 4
                        </h3>
                        <div className="relative w-full overflow-hidden pt-[56.25%]">
                            <p>
                                <iframe
                                    className="absolute top-0 left-0 right-0 w-full h-full border-none"
                                    src="https://www.youtube.com/embed/2_pgX5WZSPE"
                                    width="560"
                                    title="Live Intensivão podio.digital"
                                    height="315"
                                    allowFullScreen="allowFullScreen"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                ></iframe>
                            </p>
                        </div>
                        <p className="text-center font-bold pt-2 text-xl">
                            AULA 1, 2 e 3 - materiais já disponíveis!
                        </p>
                    </div>
                    <div className="w-full lg:max-w-[45%]">
                        <h1 className="text-5xl font-bold">
                            <span className="text-secondary">INTENSIVÃO</span>{' '}
                            OLIMPÍADA BRASILEIRA DE INFORMÁTICA 2024!
                        </h1>
                        <p className="py-6 text-xl">
                            Treine em 4 aulas ao-vivo,{' '}
                            <span className="text-secondary font-bold">
                                todo domingo até a primeira fase da OBI
                            </span>
                            , com a equipe com maior resultados na modalidade
                            programação da OBI 2023, e o melhor,{' '}
                            <span className="font-bold text-2xl text-secondary">
                                100% gratuito
                            </span>
                            !
                            <br />
                            Para ter acesso as{' '}
                            <span className="text-secondary font-bold">
                                aulas gravadas + materiais extras
                            </span>{' '}
                            veja abaixo.
                            <br />
                        </p>
                    </div>
                </div>
            </div>
            <h1 className="text-center text-4xl py-4 font-bold text-secondary">
                Materiais Intensivão
            </h1>
            <div className="hero py-12">
                <div className="hero-content flex flex-col lg:flex-row-reverse w-full items-center">
                    <div className="w-full">
                        <div className="relative w-full overflow-hidden pt-[56.25%]">
                            <iframe
                                className="absolute top-0 left-0 right-0 w-full h-full border-none"
                                src="https://www.youtube.com/embed/ScDQNR5hX9k"
                                width="560"
                                title="Live Intensivão podio.digital"
                                height="315"
                                allowFullScreen="allowFullScreen"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            ></iframe>
                        </div>
                        <p className="text-center font-bold pt-2 text-xl">
                            Aúdio melhora nos 4:30
                        </p>
                    </div>
                    <div className="w-full lg:max-w-[45%] text-center">
                        <h1 className="text-5xl font-bold text-secondary">
                            AULA 1
                        </h1>
                        <p className="py-6 text-3xl">
                            <span className="text-secondary font-bold text-4xl">
                                Conteúdo
                            </span>
                            <br />
                            <ul className="list-disc list-inside inline-block text-center">
                                <li>Estrutura do código</li>
                                <li>Variáveis</li>
                                <li>Estruturas Condicionais</li>
                            </ul>
                        </p>
                        <a
                            className="btn btn-primary text-xl"
                            href={PdfAula1}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            MATERIAIS EXTRAS
                        </a>
                    </div>
                </div>
            </div>
            <div className="hero">
                {' '}
                <div className="hero-content flex flex-col lg:flex-row-reverse w-full items-center">
                    <div className="w-full">
                        <div className="relative w-full overflow-hidden pt-[56.25%]">
                            <iframe
                                className="absolute top-0 left-0 right-0 w-full h-full border-none"
                                src="https://www.youtube.com/embed/aspHEKKJk3M"
                                width="560"
                                title="Live Intensivão podio.digital"
                                height="315"
                                allowFullScreen="allowFullScreen"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            ></iframe>
                        </div>
                    </div>
                    <div className="w-full lg:max-w-[45%] text-center">
                        <h1 className="text-5xl font-bold text-secondary">
                            AULA 2
                        </h1>
                        <p className="py-6 text-3xl">
                            <span className="text-secondary font-bold text-4xl">
                                Conteúdo
                            </span>
                            <br />
                            <ul className="list-disc list-inside inline-block text-center">
                                <li>Estruturas de Repetição/Loops</li>
                                <li>Vetores</li>
                            </ul>
                        </p>
                        <a
                            className="btn btn-primary text-xl"
                            href={PdfAula2}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            MATERIAIS EXTRAS
                        </a>
                    </div>
                </div>
            </div>
            <div className="hero">
                {' '}
                <div className="hero-content flex flex-col lg:flex-row-reverse w-full items-center">
                    <div className="w-full">
                        <div className="relative w-full overflow-hidden pt-[56.25%]">
                            <iframe
                                className="absolute top-0 left-0 right-0 w-full h-full border-none"
                                src="https://www.youtube.com/embed/6Sl1jI8_xFI"
                                width="560"
                                title="Live Intensivão podio.digital"
                                height="315"
                                allowFullScreen="allowFullScreen"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            ></iframe>
                        </div>
                    </div>
                    <div className="w-full lg:max-w-[45%] text-center">
                        <h1 className="text-5xl font-bold text-secondary">
                            AULA 3
                        </h1>
                        <p className="py-6 text-3xl">
                            <span className="text-secondary font-bold text-4xl">
                                Conteúdo
                            </span>
                            <br />
                            <ul className="list-disc list-inside inline-block text-center">
                                <li>Estruturas de Repetição/Loops</li>
                                <li>Vetores</li>
                            </ul>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default IntensivaoInfo
