const MentoriaOptions = () => {
    const options = [
        {
            title: 'Semanal (1 aula)',
            beforePrice: '85',
            afterPrice: '80',
            perClass: '80',
            link: 'https://wa.me/558599653229?text=Ol%C3%A1!%20Tenho%20interesse%20no%20plano%20individual%20de%201%20aula%20do%20podio.digital%20e%20gostaria%20de%20mais%20informa%C3%A7%C3%B5es',
        },
        {
            title: 'Trimestral (12 aulas)',
            beforePrice: '900',
            afterPrice: '840',
            perClass: '70',
            big: true,
            link: 'https://wa.me/558599653229?text=Ol%C3%A1!%20Tenho%20interesse%20no%20plano%20Trimestral%20de%2012%20aulas%20do%20podio.digital%20e%20gostaria%20de%20mais%20informa%C3%A7%C3%B5es',
        },
        {
            title: 'Mensal (4 aulas)',
            beforePrice: '320',
            afterPrice: '300',
            perClass: '75',
            link: 'https://wa.me/558599653229?text=Ol%C3%A1!%20Tenho%20interesse%20no%20plano%20Mensal%20de%204%20aulas%20do%20podio.digital%20e%20gostaria%20de%20mais%20informa%C3%A7%C3%B5es',
        },
    ]

    return (
        <div className="grid grid-cols-1 lg:grid-cols-3 gap-8 mt-8 pb-[200px] px-6">
            {options.map((option, index) => (
                <div
                    key={index}
                    className="card bg-base-100 shadow-xl max-w-[400px] mx-auto"
                >
                    <div className="card-body text-center">
                        <h3 className="card-title text-3xl">
                            <p className="text-center">{option.title}</p>{' '}
                            {option.big && (
                                <div className="badge badge-secondary whitespace-nowrap">
                                    MELHOR PREÇO
                                </div>
                            )}
                        </h3>
                        <p className="text-2xl text-gray-700 mb-4">
                            <span className="text-secondary">
                                R${option.afterPrice}
                            </span>{' '}
                            (R${option.perClass} por aula)
                        </p>
                        <div className="card-actions justify-center">
                            <a
                                href={`${option.link}`}
                                className="bg-[#FFA400] text-white border-none px-6 py-2 text-lg uppercase font-semibold tracking-widest shadow-[0_0_0.5rem_#FFA400,0_0_1rem_#FFA400,0_0_2rem_#FFA400] hover:bg-white hover:text-[#FFA400] hover:shadow-[0_0_0.75rem_#FFA400,0_0_1.5rem_#FFA400,0_0_3rem_#FFA400,0_0_4.5rem_#FFA400] transition-all"
                            >
                                Começar agora
                            </a>
                        </div>
                    </div>
                </div>
            ))}
        </div>
    )
}

export default MentoriaOptions
