import { Link } from 'react-router-dom/cjs/react-router-dom.min'

const GratisInfo = ({ history }) => {
    return (
        <div className="hero pb-16 pt-5">
            <div className="hero-content flex-col lg:flex-row-reverse w-full min-h-[93vh]">
                <div className="flex flex-col w-full h-[50%] lg:flex-row">
                    <div className="grid flex-grow h-32 card rounded-box place-items-center">
                        <Link
                            className="btn btn-primary text-3xl"
                            to="/informatica/intensivao"
                        >
                            INTENSIVÃO OBI F1
                        </Link>
                    </div>
                    <div className="divider lg:divider-horizontal">ou</div>
                    <div className="grid flex-grow card rounded-box place-items-center">
                        <h3 className="text-3xl pb-3 font-bold text-primary">
                            Soluções OBI FASE 1
                        </h3>
                        <div className="flex flex-col">
                            <a
                                href="https://youtu.be/ssX7mj4m1S4?si=N-HQY4tMkPJcoVoB"
                                target="_blank"
                                rel="noopener noreferrer"
                                className="btn btn-secondary text-2xl"
                            >
                                BACTÉRIAS
                            </a>
                            <br />
                            <a
                                href="https://youtu.be/1JfiIIV1IqY?si=PJxcdVHOeRmo0mc6"
                                target="_blank"
                                rel="noopener noreferrer"
                                className="btn btn-secondary text-2xl"
                            >
                                CONCURSO
                            </a>
                            <br />
                            <a
                                href="https://youtu.be/neqISvpdRxk?si=iNjgEN38AcX19E5M"
                                target="_blank"
                                rel="noopener noreferrer"
                                className="btn btn-secondary text-2xl"
                            >
                                OGRO
                            </a>
                            <br />
                            <a
                                href="https://youtu.be/4SBSeVlyFvg?si=xGdJdE7UfF-Ix8pj"
                                target="_blank"
                                rel="noopener noreferrer"
                                className="btn btn-secondary text-2xl"
                            >
                                RELÓGIO
                            </a>
                            <br />
                            <a
                                href="https://youtu.be/F9dA7jZPF78?si=YLElO4-Hy4XmDb6m"
                                target="_blank"
                                rel="noopener noreferrer"
                                className="btn btn-secondary text-2xl"
                            >
                                JOGO DA VIDA
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default GratisInfo
