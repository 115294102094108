import { api } from "./api"
export const TOKEN_KEY = '@podio-Token'

export async function isProfessor() {
    const token = getToken(); // Recupera o token do localStorage
    if (!token) {
        console.error('Usuário não autenticado, token não encontrado.');
        return "N/A"; // Retorna falso se não estiver autenticado
    }

    try {
        const response = await api.get('/user-info'); // O token é automaticamente adicionado pelo interceptor
        if (response.status !== 201) { 
            throw new Error('Erro ao obter informações do usuário');
        }

        // Acesse a propriedade correta para verificar se o usuário é professor
        const isProf = response.data.user.teacher; // Ajuste de acordo com a estrutura da resposta
        return isProf;

    } catch (error) {
        console.error('Erro ao verificar se o usuário é professor:', error);
        
        return false; // Retorna falso caso a verificação falhe
    }
}

export const getToken = () => {
    const itemStr = localStorage.getItem(TOKEN_KEY)
    if (!itemStr) return null
    const item = JSON.parse(itemStr)
    const now = new Date()
    if (now.getTime() > item.expiry) {
        localStorage.removeItem(TOKEN_KEY)
        return null
    }
    return item.value
}

export const isAuthenticated = () => {
    return getToken() !== null
}
export const login = (token) => {
    const now = new Date()

    const item = {
        value: token,
        expiry: now.getTime() + 60 * 60 * 24 * 1000,
    }
    localStorage.setItem(TOKEN_KEY, JSON.stringify(item))
}

export const logout = () => {
    localStorage.removeItem(TOKEN_KEY)
}
