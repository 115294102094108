import React from 'react';

const PoliticaPrivacidade = () => {
  return (
    <div className="max-w-4xl mx-auto p-6 bg-white shadow-md rounded-lg">
      <header className="mb-6">
        <h1 className="text-3xl font-bold text-gray-800">Política de Privacidade</h1>
        <p className="text-gray-600">Última atualização: 30 de outubro de 2024.</p>
      </header>

      <section className="mb-6">
        <p className="text-gray-700 mb-4">
          Esta Política de Privacidade tem como objetivo informar a você, usuário do Podio.digital, sobre quais dos seus dados pessoais são coletados, além de como eles são tratados, em que situações são compartilhados e quando são excluídos. Esta Política se aplica a todos os serviços disponibilizados pelo Podio.digital na Plataforma, por meio de conteúdo escrito, impresso, digital e/ou eletrônico, entrega de produtos, sites, aplicativos, interfaces, além de outros produtos e serviços que possam ser ofertados pelo Podio.digital.
        </p>
        <p className="text-gray-700">
          De tempos em tempos, o Podio.digital aprimora suas práticas e políticas de privacidade. Atualizações a esta Política entrarão em vigor no momento da sua publicação na Plataforma. Assim, você entende ser de sua responsabilidade consultar esta Política de tempos em tempos, para verificar se concorda com as suas eventuais atualizações. Caso você não aceite as atualizações da Política, o seu acesso à Plataforma será suspenso. O Podio.digital se esforçará para comunicar os usuários sempre que esta Política for atualizada, mas se você continuar usando a Plataforma após a publicação de uma versão atualizada da Política, sua aceitação será presumida.
        </p>
      </section>

      <section className="mb-6">
        <h2 className="text-2xl font-semibold text-gray-800 mb-2">Aceite dos Termos</h2>
        <p className="text-gray-700 mb-4">
          Ao acessar a Plataforma, você deverá ler o conteúdo desta Política. Se estiver de acordo com as condições apresentadas nesta Política e quiser utilizar a Plataforma, precisará manifestar o seu consentimento por meio da seleção da caixa de seleção (checkbox) correspondente à opção “Li e concordo com a Política de Privacidade”. Esse consentimento poderá ser cancelado a qualquer momento, por meio do nosso Canal de Comunicação indicado no item 13 desta Política. Ao cancelar o seu consentimento, você compreende que isso poderá restringir ou suspender o seu acesso à Plataforma, bem como a prestação dos serviços. De todo modo, assim que o Podio.digital receber sua solicitação, seus dados serão excluídos, exceto nos casos em que o Podio.digital seja obrigada por lei a armazená-los.
        </p>
        <p className="text-red-600 font-bold">
          AO SELECIONAR A CAIXA DE SELEÇÃO (CHECKBOX) CORRESPONDENTE À OPÇÃO “LI E CONCORDO COM A POLÍTICA DE PRIVACIDADE”, VOCÊ DÁ O SEU CONSENTIMENTO PARA QUE O PODIO.DIGITAL E SEUS PARCEIROS TRATEM SEUS DADOS COLETADOS POR MEIO DA PLATAFORMA, INCLUSIVE EVENTUAIS DADOS SENSÍVEIS, PARA AS FINALIDADES DESCRITAS NESTA POLÍTICA.
        </p>
      </section>

      <section className="mb-6">
        <h2 className="text-2xl font-semibold text-gray-800 mb-2">Dados Coletados</h2>
        <p className="text-gray-700 mb-4">O PODIO.DIGITAL coleta todos os dados inseridos pelo Usuário na Plataforma, tais como:</p>
        <ul className="list-disc list-inside text-gray-700 mb-4">
          <li>Nome completo;</li>
          <li>Endereço de e-mail;</li>
        </ul>
      </section>

      <section className="mb-6">
        <h2 className="text-2xl font-semibold text-gray-800 mb-2">Utilização e Tratamento dos Dados</h2>
        <p className="text-gray-700 mb-4">Os Dados que coletamos dos Usuários são arquivados em nossa base de dados e tratados para:</p>
        <ul className="list-disc list-inside text-gray-700 mb-4">
          <li>Apresentar a Plataforma e seus conteúdos ao Usuário;</li>
          <li>Customizar, personalizar e adaptar o aprendizado do Usuário;</li>
          <li>Comunicar-se com o Usuário e responder suas comunicações;</li>
          <li>Verificar a identidade do Usuário;</li>
          <li>Fornecer informações, produtos ou serviços que o Usuário solicitou ao Podio;</li>
          <li>Fornecer avisos sobre a conta do Usuário, incluindo avisos de vencimento e renovação da assinatura;</li>
          <li>Realizar obrigações e exercer os direitos do Podio.digital decorrentes de quaisquer contratos celebrados entre o Usuário e o Podio.digital;</li>
          <li>Notificar o Usuário sobre atualizações na Plataforma, na Política, nos Termos de Uso da Plataforma ou em quaisquer serviços oferecidos pelo Podio.digital;</li>
          <li>Permitir que o Usuário participe dos recursos interativos da Plataforma;</li>
          <li>Melhorar a qualidade dos Serviços, podendo realizar análises estatísticas, empíricas, quantitativas ou qualitativas das características e do comportamento coletivos dos Usuários;</li>
          <li>Administrar cursos, provas, testes, avaliações, exercícios, projetos e outras atividades por meio da Plataforma;</li>
          <li>Rastrear a participação, o progresso e a conclusão de atividades realizadas pelo Usuário na Plataforma;</li>
          <li>Compartilhar informações de desempenho do Usuário com instrutores, professores, assistentes ou indivíduos designados pelo Podio.digital;</li>
          <li>Permitir o envio de ofertas ou descontos em cursos e atividades;</li>
          <li>Permitir a comunicação entre o Usuário e o Podio.digital.</li>
        </ul>
      </section>

      <section className="mb-6">
        <h2 className="text-2xl font-semibold text-gray-800 mb-2">Cookies</h2>
        <p className="text-gray-700 mb-4">O Podio.digital poderá utilizar cookies ou tecnologias semelhantes para personalizar a experiência do Usuário na Plataforma. O Usuário pode alterar as configurações do navegador para bloquear cookies, mas isso poderá afetar o funcionamento da Plataforma.</p>
      </section>

      <section className="mb-6">
        <h2 className="text-2xl font-semibold text-gray-800 mb-2">Links de Terceiros</h2>
        <p className="text-gray-700 mb-4">O Podio.digital pode divulgar links para outros sites administrados por empresas distintas (“Sites de Terceiros”), que possuem suas próprias políticas de privacidade e não são de responsabilidade do Podio.digital.</p>
      </section>

      <section className="mb-6">
        <h2 className="text-2xl font-semibold text-gray-800 mb-2">Armazenamento dos Dados</h2>
        <p className="text-gray-700 mb-4">As informações dos Usuários serão armazenadas pelo Podio.digital em servidores próprios ou por ela contratados, localizados no Brasil ou no exterior, com medidas de segurança para proteger os Dados.</p>
      </section>

      <footer className="mt-6 border-t pt-4 text-center">
        <p className="text-gray-600">© Podio.digital - Todos os direitos reservados.</p>
      </footer>
    </div>
  );
};

export default PoliticaPrivacidade;
