import React, { useEffect, useState } from 'react'
import GroupButtons from '../../components/GroupButtons'
import { api } from '../../services/api';

const Solicitacoes = () => {
    const [token, setToken] = useState(0);
    const [studentId, setStudentId] = useState('');
    const [classRequests, setClassRequests] = useState([]);
    const [errorMessage, setErrorMessage] = useState('');

    // Função para buscar as informações do usuário
    const handleGet = async () => {
        try {
            const response = await api.get('/user-class', {});
            setToken(response.data.user.tokens);
            setErrorMessage('');
        } catch (err) {
            setErrorMessage('Houve um problema ao consultar suas informações T.T');
        }
    };

    // Função para buscar as solicitações de aula feitas pelo aluno
    const fetchClassRequests = async () => {
        try {
            const response = await api.get('/reservation-request/student', {
                params: { studentId },
            });
            setClassRequests(response.data.reservation);
        } catch (err) {
            console.error(err);
            const errorMessage = err.response?.data?.message || 'Erro ao buscar solicitações.';
            setErrorMessage(errorMessage);
            setTimeout(() => setErrorMessage(''), 5000);
        }
    };

    // Função para cancelar a solicitação de reserva
    const handleCancelReservation = async (reservationId) => {
        try {
            await api.delete(`/cancel-reservation/${reservationId}`);
            setClassRequests(classRequests.filter((request) => request._id !== reservationId));
            handleGet()
        } catch (err) {
            setErrorMessage('Erro ao cancelar a solicitação.');
        }
    };

    // Buscar as informações do usuário ao carregar a página
    useEffect(() => {
        const fetchUserInfo = async () => {
            try {
                const userResponse = await api.get('/user-info', {});
                const userId = userResponse.data.user._id;
                setStudentId(userId);
            } catch (err) {
                setErrorMessage('Erro ao obter informações do usuário.');
            }
        };
        fetchUserInfo();
    }, []);

    // Buscar as solicitações de aula quando o studentId estiver disponível
    useEffect(() => {
        if (studentId) {
            handleGet();
            fetchClassRequests();
        }
    }, [studentId]);

    // Função para filtrar as solicitações com base na data
    const filterRequestsByDate = (requests) => {
        const currentDate = new Date();
        currentDate.setHours(0, 0, 0, 0); // Data atual
        return requests.filter((request) => {
            const slotDate = new Date(request.slotDate); 
            return slotDate >= currentDate; // Filtrar apenas aulas futuras ou no dia atual
        });
    };

    return (
        <div className="py-4">
            {errorMessage && (
                <div className="fixed bottom-4 z-50 right-4 bg-red-500 text-white px-4 py-2 rounded">
                    {errorMessage}
                </div>
            )}
            <GroupButtons token={token} />
            <div className="min-w-80 mr-6 ml-4 bg-white py-4 rounded-md flex flex-col items-center">
                <h3 className="px-4 font-bold mb-10 text-orange-500 text-2xl">Pedidos de aula</h3>
                <ul className="px-4 w-full">
                    {classRequests.length > 0 ? (
                        filterRequestsByDate(classRequests).map((request) => (
                            <li
                                key={request._id}
                                className="mb-2 p-2 border border-gray-300 rounded-md flex justify-between items-center"
                            >
                                {/* Informações de Professor, Data e Horário */}
                                <div className="flex flex-col" >
                                    <p><strong>Professor:</strong> {request.teacherName}</p>
                                    <p><strong>Data:</strong> {new Date(request.slotDate).toLocaleDateString('pt-BR')}</p>
                                    <p><strong>Horário:</strong> {new Date(request.slotDate).toLocaleTimeString('pt-BR', { hour: '2-digit', minute: '2-digit' })}</p>
                                    {request.status === 'PENDING' && (
                                        <div className="mt-2">
                                            <button
                                                onClick={() => handleCancelReservation(request._id)}
                                                className="px-4 py-2 bg-red-500 text-white rounded-md"
                                            >
                                                Cancelar
                                            </button>
                                        </div>
                                    )}
                                </div>

                                <div className="">
                                    <p>

                                        <strong
                                            className={request.status === 'ACCEPTED' ? 'text-green-500 text-lg' : request.status === 'REJECTED' ? 'text-red-500 text-lg' : 'text-lg'}
                                        >
                                            {request.status === 'PENDING' ? 'Em espera' : request.status === 'ACCEPTED' ? 'Aprovado' : 'Negado'}
                                        </strong>
                                    </p>

                                </div>
                            </li>
                        ))
                    ) : (
                        <p className="text-gray-500">Nenhum pedido de aula no momento.</p>
                    )}
                </ul>
            </div>
        </div>
    );
};

export default Solicitacoes;
